import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchData(ctx, queryParams) {
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .post('/users/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchGroupData(ctx, queryParams){
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .post('/groups/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },
        addUser(ctx, queryParams){
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .post('/users/v1/add',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },
        updateUser(ctx, queryParams){
           
             var data = JSON.stringify(queryParams);
            return new Promise((resolve, reject) => {
                axios
                  .put('/users/v1/update/'+queryParams.id,data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        fetchDeleteUser(ctx,id){
            return new Promise((resolve, reject) => {
                axios
                  .delete('/users/v1/delete/'+id)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })
        }
    }
}